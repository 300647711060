import React from 'react';
import RostoTriste from '../../../../assets/rosto-triste.svg';
import { AiOutlineLeft } from 'react-icons/ai';
import './styles.css';
import { useHistory } from 'react-router-dom';

function PontosBonus() {
    const history = useHistory();
  return (
      <div className="container">
          <h3 className="title">Você ainda não faz parte do nosso programa de fidelidade Pegapontos!</h3>
          <img src={RostoTriste} alt="Rosto triste"></img>
          <label className="subtitle">Para ser um parceiro e participar do programa de fidelidade, clique em cadastrar:</label>
          <a href="https://play.google.com/store/apps/details?id=com.pegapontos.pegapontos_membro" className="btn-cadastrar">CADASTRAR</a>
          <button className="btn-voltar" onClick={() => history.goBack()}>< AiOutlineLeft/>Anterior</button>
      </div>
  );
}

export default PontosBonus;