import React, { useState } from "react";
import ButtonFrotaBank from "../../shared/Components/ButtonFrotaBank";
import InputFrotabank from "../../shared/Components/InputFrotabank";
import Spacer from "../../shared/Components/Spacer";
import { useHistory } from "react-router-dom";
import { getCookieValue } from "@/shared/utils/cookieService";

import {
  trocarPontos,
} from "@/services/fetchService";
import moment from "moment";
import openNotification from "@/shared/utils/openNotification";
import { currencyFormatter } from "../../utils/formaters";

function ConfirmacaoTrocaPontos() {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const state = history.location.state;

  async function handleConfirmar() {
    try {
      setIsLoading(true);
      const token = getCookieValue('authToken');
      const cpf = getCookieValue('user');

      let dataTrocaPontos = {
        cpf: cpf,
        valor: state.valor,
        qtd: state.pontosTrocar,
      };

      dataTrocaPontos = JSON.stringify(dataTrocaPontos);
      const trocarPontosResponse = await trocarPontos(dataTrocaPontos, token);

      if (trocarPontosResponse.status === 200) {
        const register = trocarPontosResponse.data.dta_registro;
        const registerDate = moment(register).format("DD/MM/yyyy");
        const registerTime = moment(register).format("HH:mm");

        const rescue = trocarPontosResponse.data.dta_resgate;
        const rescueDate = moment(rescue).format("DD/MM/yyyy");
        const rescueTime = moment(rescue).format("HH:mm");

        openNotification(
          "success",
          "Pontos trocados com sucesso! :)",
          "Seus pontos pegapontos foram convertidos em saldo no Frotabank com sucesso!"
        );
        history.push("/saldoEnviadoReais", {
          registerDate,
          registerTime,
          rescueDate,
          rescueTime,
          situacao: trocarPontosResponse.data.situacao,
          val_resgate: parseFloat(state.valor),
        });
      } else if (trocarPontosResponse.status === 409) {
        history.push("/blockedAccount", {
          mensagem: trocarPontosResponse.data.mensagem ?? trocarPontosResponse.data,
        });
      } else {
        openNotification(
          "error",
          "Falha ao trocar de pontos",
          trocarPontosResponse.data.mensagem ?? trocarPontosResponse.data
        );
      }
      toggleModal();
      state.senha = "";
    } catch (error) {
      openNotification(
        "error",
        "Falha ao trocar de pontos",
        (error.data?.mensagem ?? error?.data) ?? "Ocorreu um erro desconhecido"
      );
    } finally {
      setIsLoading(false);
    }
  }

  function toggleModal() {
    setIsModalVisible(!isModalVisible);
  }

  return (
    <div className="container">
      <Spacer margin="20px" />
      <div style={{ fontFamily: "Arial" }} className="title">
        <label>Você tem certeza que gostaria de converter?</label>
      </div>
      <InputFrotabank
        value={`${state.pontosTrocar} ponto(s)`}
        placeholder="Qtd. pontos"
        disabled
      />
      <div style={{ fontSize: "50px" }}> = </div>
      <InputFrotabank
        disabled
        value={`${currencyFormatter(state.valor)} reais`}
        placeholder="R$ 0,00"
      />
      <Spacer />
      <div className="center subtitle2" style={{ color: "#037983" }}>
        Após clicar em "continuar". Não será possível reverter a operação.
      </div>
      <ButtonFrotaBank
        width="60%"
        title="Confirmar"
        isLoading={isLoading}
        onClick={handleConfirmar}
        disabled={isLoading}
      />
      <Spacer margin="10px" />
      <ButtonFrotaBank
        bgColor="#C4C4C4"
        width="60%"
        title="Cancelar"
        isLoading={isLoading}
        onClick={() => history.goBack()}
        disabled={isLoading}
      />
      <Spacer />
    </div>
  );
}

export default ConfirmacaoTrocaPontos;
