import React, { useEffect, useState } from "react";
import "./styles.css";

import { useHistory } from "react-router-dom";

import PegaPontosSection from "../../shared/Components/PegapontosSection/index";
import Tips from "./Components/Tips/index";
import {
  fetchEmbaixadorData,
  getSaldoMembroPegaPontos,
} from "../../services/fetchService";
import ButtonFrotaBank from "../../shared/Components/ButtonFrotaBank";
import Spacer from "../../shared/Components/Spacer";
import AuthError from "../../shared/Components/AuthError";
import openNotification from "../../shared/utils/openNotification";
import { getCookieValue } from "../../shared/utils/cookieService";
import Loading from "../../shared/Components/Loading";
import TelaSemIndicacao from "./Components/TelaSemIndicacao";

function HomeIndicacao() {
  const [embaixador, setEmbaixador] = useState({});
  const [isFetching, setIsFetching] = useState(true);
  const [pontosPegapontos, setPontospegapontos] = useState(0);
  const [dataProcessoPegaPontos, setDataProcessoPegaPontos] = useState(null);
  const [codigoIndicacao, setCodigoIndicacao] = useState("");
  const [authError, setAuthError] = useState(false);
  const [status, setStatus] = useState(null)

  useEffect(() => {
    async function fetchData() {
      if (isFetching) {
        try {
          const authToken = getCookieValue("authToken");
          const cpf = getCookieValue("user");
          if (authToken === null) {
            setAuthError(true);
            return;
          }

          const embaixador = await fetchEmbaixadorData(cpf, authToken).catch(
            (err) => setAuthError(true)
          );
          setStatus(embaixador.status === 202 ? true : false)

          if (embaixador.data.has_id === '') setStatus(true)
          setEmbaixador(embaixador.data);
          console.log(embaixador)
          setCodigoIndicacao(embaixador.data.has_id);
          const membroSaldoResponse = await getSaldoMembroPegaPontos(
            cpf,
            authToken
          ).catch((err) => setAuthError(true));

          const pontos = membroSaldoResponse.data.saldo.saldoPontos;
          const {data}= membroSaldoResponse.data?.saldo;
          setDataProcessoPegaPontos(data);
          var somaPontos = pontos.reduce(function (acumulador, saldo) {
            return (acumulador += saldo.saldoPontos);
          }, 0);
          setPontospegapontos(somaPontos);
        } catch (e) {
          alert("Aconteceu um erro inesperado!");
          console.log(e);
        } finally {
          setIsFetching(false);
        }
      }
    }

    fetchData();
  }, [isFetching]);

  const history = useHistory();

  function handleTrocarPontos() {
    history.push("/trocarPontos", embaixador.val_pegapontos);
  }

  async function handleShare() {
    const shareData = {
      title: "Bem vindo ao Frotabank",
      text: "Acesse este link para se tornar o mais novo membro Frotabank: ",
      url: `${process.env.REACT_APP_LINK}?utm_content=${codigoIndicacao}`,
    };
    try {
      if (navigator.share) {
        navigator.share(shareData);
      } else {
        openNotification(
          "errorbrowser",
          "Este browser não suporta compartilhamento!"
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleExtratoRecompensa() {
    history.push("/extratoRecompensa", embaixador);
  }

  // function copyToClipBoard() {
  //   navigator.clipboard.writeText(codigoIndicacao).then(
  //     () => {
  //       openNotification(
  //         "pontos",
  //         "Código copiado",
  //         "Seu código foi copiado com sucesso!"
  //       );
  //     },
  //     (reason) => {
  //       openNotification(
  //         "erro",
  //         "Erro",
  //         "Aconteceu algum erro ao tentar copiar seu código!"
  //       );
  //     }
  //   );
  // }

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : status ? (
        <TelaSemIndicacao />
      ) : !authError ? (

        <div className="container">
          <div className="title-container">
            <h1 className="title-convide-amigos">
              Convide seus amigos para<br /> abrir a conta Frotabank
            </h1>
            <div className="convidar-button-container">
              <Spacer />
              <ButtonFrotaBank
                onClick={handleShare}
                bgColor="white"
                color="#037983"
                title="Convidar"
                fontSize="2.6rem"
              />
            </div>
            <div className="seu-codigo">
              <label>
                Seu código:
                <span style={{ paddingLeft: "5px" }}>{codigoIndicacao}</span>
                {/* {codigoIndicacao !== '' &&  */}
                {/* <BiCopy style={{cursor: 'pointer', marginLeft: '8px',fontSize: '20px'}} onClick={copyToClipBoard}/>} */}
                {/* </span> */}
              </label>
            </div>
          </div>
          <div className="title2">
            Clique no botão abaixo e veja <br />o andamento das suas indicações
          </div>

          <ButtonFrotaBank
            title="Ver minhas indicações"
            onClick={handleExtratoRecompensa}
          />

          <PegaPontosSection qntdPontos={pontosPegapontos} dataProcesso={dataProcessoPegaPontos} />

          <ButtonFrotaBank
            title="Trocar meus pontos"
            onClick={handleTrocarPontos}
          />

          <div style={{ marginTop: "40px" }} className="title2">
            <p>
              Veja como é simples <br /> indicar seus amigos:
            </p>
          </div>

          <Tips />
          
        </div>
      ) : (
        <AuthError />
      )}
    </>
  );
}



export default HomeIndicacao;
