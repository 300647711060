import React from 'react';
import celularChat from '../../../../assets/celular-chat.png';
import nuvemDownload from '../../../../assets/nuvem-download.png';
import cartaoDinheiro from '../../../../assets/cartao-dinheiro.png';
import './styles.css';
import { Space } from 'antd';

function Tips() {
    return (
        <section className="tips-container">
            <div className="img-text">
                <div className="icon-container">
                    <img id="celular-img" src={celularChat} alt="celular"></img>
                </div>
                <li>
                    Compartilhe seu link de convite com seus amigos motoristas.<br/>
                    Categoria da CNH C, D ou E.
                </li>
            </div>
            <div className="img-text">
                <div className="icon-container">
                    <img id="nuvem-img" src={nuvemDownload} alt="nuvem-download"></img>
                </div>
                <li>
                    Seus amigos devem baixar o app <br /> Frotabank com seu link de convite.
                </li>
            </div>
            <div className="img-text">
                <div className="icon-container">
                    <img id="cartao-img" src={cartaoDinheiro} alt="cartao"></img>
                </div>
                <li>
                    Você contribui com a nossa missão de levar liberdade financeira para quem está na estrada.
                </li>
            </div>
              <div className="img-texts">
              <br>
              </br>
                <li>
                   Versão {process.env.REACT_APP_BUILD_NUMBER}
                </li> 
            </div>
            
        </section>
    );
}

export default Tips;