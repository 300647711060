import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import './global.css';
import 'antd/dist/antd.css';

ReactDOM.render(

    <Routes />,
  document.getElementById('root')
);
