import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
// import ConvidarAmigo from './pages/ConvidarAmigo/index';
// import HomeIndicacao from './pages/HomeIndicacao/index';
import ExtratoRecompensa from './pages/ExtratoRecompensa/index';
import BonusIndicacao from './pages/BonusIndicacao/index';
import EmEspera from './pages/EmEspera/index';
import ResgatarBonus from './pages/ResgatarBonus/index';
import TrocarPontos from './pages/TrocarPontos';
import ConfirmacaoTrocaPontos from './pages/ConfirmacaoTrocaPontos';
import SaldoEnviado from './pages/SaldoEnviado';
import BlockedAccount from './pages/BlockedAccount';
import ExtratoResgate from './pages/ExtratoResgate';
import RecompensaPontos from './pages/RecompensaPontos';
import CadastroPegapontos from './pages/CadastroPegaPontos';
import Wrapper from './pages/Wrapper';
import SaldoEnviadoReais from './pages/SaldoEnviadoReais';

export default function Routes() {
  useEffect(() => {

  }, []); 
  return (
    <BrowserRouter>
        <Switch>
          {/* <Route path="/" exact component={ConvidarAmigo}/> */}
          <Route path='/' exact component={Wrapper}/>
          <Route path='/extratoRecompensa' exact component={ExtratoRecompensa}/>
          <Route path='/bonusIndicacao' component={BonusIndicacao}/>
          <Route path='/emEspera' component={EmEspera}/>
          <Route path='/resgatarBonus' component={ResgatarBonus}/>
          <Route path='/trocarPontos' component={TrocarPontos}/>
          <Route path='/confirmacaoTrocaPontos' component={ConfirmacaoTrocaPontos} />
          <Route path='/saldoEnviado' component={SaldoEnviado} />
          <Route path='/blockedAccount' component={BlockedAccount} />
          <Route path='/extratoResgate' component={ExtratoResgate} />
          <Route path='/recompensaPontos' component={RecompensaPontos} />
          <Route path='/cadastroPegapontos' component={CadastroPegapontos} />
          <Route path='/saldoEnviadoReais' component={SaldoEnviadoReais} />
        </Switch>
    </BrowserRouter>
  )
}
