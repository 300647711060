import React from 'react';
import Spacer from '../../../../shared/Components/Spacer';
import ButtonFrotaBank from '../../../../shared/Components/ButtonFrotaBank';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import openNotification from '../../../../shared/utils/openNotification';
import './styles.css';

function ExtratoResgateContainer({extrato}) {
  const history = useHistory();
  const subtitle = extrato.situacao === "Pendente" ? "Em processamento" : "Confirmado";
  const registerDate = moment(extrato.dta_registro).format('DD/MM/yyyy');
  const registerTime = moment(extrato.dta_registro).format('HH:mm');
  
  function handleComprovante(){
    if(extrato.situacao === 'Pendente'){
      openNotification(extrato.dta_resgate, 'Comprovante indisponível', `Agendamento de transferência para o Frotabank realizado. Em processamento.`);
    }
    else {
      history.push('/saldoEnviadoReais', 
      { 
        registerDate,
        registerTime,
        val_resgate: parseFloat(extrato.val_resgate),
        situacao: extrato.situacao
      });
    }
    
  }

  return (
    <div className="content-container">
        <div className="extrato-data">
            <label>Valor: R$ {extrato.val_resgate || 0}</label>
            <label className="subtitle">{subtitle}</label>
            
      
        </div>
        <div className="right-side-extrato-resgate">
            <label
              style={{ fontWeight: 'bold', color: 'black' }}
              className="subtitle">
                {extrato.qtd || 0} pontos
            </label>
            <Spacer margin="4px"/>
            <ButtonFrotaBank 
              width="100%"
              className="btn-comprovante"
              title="Comprovante"
              bgColor={ extrato.situacao === "Pendente" && "#C4C4C4" }
              mini
              onClick={handleComprovante}
              />
        </div>
    </div>
  );
}

export default ExtratoResgateContainer;