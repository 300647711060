import { Divider, Radio } from "antd";
import React, { useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import "./styles.css";

function ResgatarBonus() {
  const history = useHistory();

  function handleConfirmar() {
    if (radioValue === "dinheiro") {
      history.push("/bonusIndicacao?bonus=dinheiro");
    } else if (radioValue === "pontos") {
      history.push("bonusIndicacao?bonus=pontos");
    } else {
      history.push("/bonusIndicacao");
    }
  }

  const [radioValue, setRadioValue] = useState("dinheiro");
  return (
    <div className="container">
      <label className="title">
        Bônus disponível <br /> para resgatar.
      </label>
      <div className="subtitle">Selecione a recompensa que deseja:</div>
      <div className="options">
        <Radio.Group
          onChange={(e) => setRadioValue(e.target.value)}
          value={radioValue}
        >
          <Radio value="dinheiro">
            <span className="">R$ 10,00 na conta Frotabank</span>
          </Radio>
          <Divider />
          <Radio value="pontos">
            <span>600 pontos no Pegapontos</span>
          </Radio>
          <Divider />
        </Radio.Group>
      </div>
      <button className="btn-confirmar" onClick={handleConfirmar}>
        CONFIRMAR
      </button>
      <button className="btn-voltar" onClick={() => history.goBack()}>
        <AiOutlineLeft /> Anterior
      </button>
    </div>
  );
}

export default ResgatarBonus;
