import React from "react";
import Spacer from "../../shared/Components/Spacer";
import LogoPegapontos from "../../assets/logo-pegapontos.svg";
import "./styles.css";
import ButtonFrotaBank from "../../shared/Components/ButtonFrotaBank";
import ButtonAnterior from "../../shared/Components/ButtonAnterior";
import { useHistory } from "react-router-dom";
import { getCookieValue } from "../../shared/utils/cookieService";

function RecompensaPontos() {
  const history = useHistory();
  const dateResgate = history.location.state.date;
  const timeResgate = history.location.state.time;
  return (
    <>
      <div className="container">
        <Spacer />
        <div className="title">
          <div
            onClick={() => {
              const cpf = getCookieValue('user');
              const token = getCookieValue('tokenv');
                history.replace(`/?cpf=${cpf}&token=${token}`);
              }
            }
            className="x-icon"
          >
            X
          </div>
          <Spacer />
          <label>
            Você ganhou pontos <br /> do Pegapontos
          </label>
          <div className="logo-container">
            <img src={LogoPegapontos} alt="logo-pegapontos"></img>
          </div>
          <div className="column">
            <label style={{ marginBottom: "5px" }} className="title">
              600 pontos
            </label>
            <label className="subtitle">
              {dateResgate} às {timeResgate}
            </label>
          </div>
          <Spacer />
        </div>
        <div className="subtitle">
          <label>
            Você pode usá-los nas ofertas do Pegapontos, ou converter seus
            pontos em dinheiro.
          </label>
        </div>
        <Spacer />
        <ButtonFrotaBank
          title="Converter pontos em dinheiro"
          onClick={() => history.push("/trocarPontos")}
        />
        <Spacer margin="30px" />
        <ButtonAnterior />
      </div>
    </>
  );
}

export default RecompensaPontos;
