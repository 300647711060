import React from 'react';
import { useHistory } from 'react-router-dom';
import { AiOutlineLeft } from 'react-icons/ai';
import './styles.css';


function DinheiroBonus() {
    const history = useHistory();
    function voltar(){
        history.goBack();
    }
  return (
  <div className="container saldo-conta-geral-container">
    <div className="saldo-frotabank-text">
        Saldo na conta Frotabank
    </div>
    <div className="saldo-container">
        <label className="subtitle">Bônus de indicação<br/></label>
        <label className="subtitle">23/09/2021 às 09:31</label>
        <label className="saldo-frotabank-value">R$ 10,00<br/></label>
        <label className="subtitle">Em processamento</label>
    </div>
    <button onClick={voltar} className="btn-voltar">
        <AiOutlineLeft/> Anterior
    </button>
  </div>
  )};

export default DinheiroBonus;